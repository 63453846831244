/*
*
* Custom js snippets for Startuply v1.1
* by Vivaco
*
*/
(function(){
	"use strict";

    // Headhesive init
    var options = {  // set options
            offset: 300,
            classes: {
                clone:   'fixmenu-clone',
                stick:   'fixmenu-stick',
                unstick: 'fixmenu-unstick'
            }
        };

	var fixmenu = new Headhesive('.navigation-header', options); // init

    $('#btn-month').click(function() {
        $('#btn-month').attr('class', 'btn btn-primary active m-t-10');
        $('#btn-annual').attr('class', 'btn btn-default m-t-10');
        $('#monthly').removeClass('hidden');
        $('#annually').addClass('hidden');
    });

    $('#btn-annual').click(function() {
        $('#btn-annual').attr('class', 'btn btn-primary active m-t-10');
        $('#btn-month').attr('class', 'btn btn-default m-t-10');
        $('#annually').removeClass('hidden');
        $('#monthly').addClass('hidden');
    });

})();

$(document).ready(function() {

    $('#span-testimonal-one').on('click', function () {
        $('#testimonal-one').removeClass('hidden');
        $('#testimonal-two').addClass('hidden');
        $('#testimonal-three').addClass('hidden');
        $('#span-testimonal-one').addClass('flex-active');
        $('#span-testimonal-two').removeClass('flex-active');
        $('#span-testimonal-three').removeClass('flex-active');
    });

    $('#span-testimonal-two').on('click', function () {
        $('#testimonal-two').removeClass('hidden');
        $('#testimonal-one').addClass('hidden');
        $('#testimonal-three').addClass('hidden');
        $('#span-testimonal-two').addClass('flex-active');
        $('#span-testimonal-one').removeClass('flex-active');
        $('#span-testimonal-three').removeClass('flex-active');
    });

    $('#span-testimonal-three').on('click', function () {
        $('#testimonal-three').removeClass('hidden');
        $('#testimonal-one').addClass('hidden');
        $('#testimonal-two').addClass('hidden');
        $('#span-testimonal-three').addClass('flex-active');
        $('#span-testimonal-one').removeClass('flex-active');
        $('#span-testimonal-two').removeClass('flex-active');
    });

    $('#btn-play-video').on('click', function () {
        $('#btn-play-video').addClass('hidden');
        $('#video-section').removeClass('hidden');
        $("#video-player").get(0).play();
    });
});